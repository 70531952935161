<template>
  <div v-if="$route.path !== '/login'">
    <el-container class="appBody">
      <el-aside style="background-color: #545c64;max-width: 200px">
        <leftNav></leftNav>
      </el-aside>
      <el-container>
        <el-header class="admin-head">
          <div class="user-set">
            <i class="el-icon-user-solid"></i>
            <span style="margin-right: 15px">{{userName}}</span>
            <i class="el-icon-switch-button" @click="logoOut()"></i>
          </div>
        </el-header>
        <el-main><router-view/></el-main>
      </el-container>
    </el-container>
  </div>
  <div v-else>
    <router-view/>
  </div>

</template>

<script>
import leftNav from "@/components/leftNav";
export default {
  name: "app",
  components:{
    leftNav
  },
  data(){
    return{
      userName: "",
    }
  },
  methods:{
    logoOut(){
      this.$confirm('此操作将退出登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        sessionStorage.clear();
        this.$router.push("/login");
        this.$message({
          type: 'success',
          message: '成功退出!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消退出'
        });
      });
    },
  },
  mounted() {
    let user_Login = sessionStorage.getItem("user_Login");
    if(!user_Login){
      // this.$router.push("/login")
    } else {
      this.userName = sessionStorage.getItem("user_name");
    }
  }
}


</script>

<style lang="scss">
.appBody {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  height: 101vh;
}

.el-menu {
  border-right: solid 0px #e6e6e6 !important;
}
.admin-head{
  height: 60px;
  box-shadow: 1px 1px 30px 4px rgba(0,0,0,.3);
}
.user-set{
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}

</style>
