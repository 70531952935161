import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
  }, {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
  }, {
    path: '/moban',
    name: 'moban',
    component: () => import(/* webpackChunkName: "moban" */ '../views/MobanView.vue')
  }, {
    path: '/mobanedit',
    name: 'mobanedit',
    component: () => import(/* webpackChunkName: "moban" */ '../views/MobaneditView.vue')
  }, {
    path: '/yuanqu',
    name: 'yuanqu',
    component: () => import(/* webpackChunkName: "moban" */ '../views/YuanquView.vue')
  }, {
    path: '/yuanquedit',
    name: 'yuanquedit',
    component: () => import(/* webpackChunkName: "moban" */ '../views/YuanqueditView.vue')
  }, {
    path: '/order',
    name: 'order',
    component: () => import(/* webpackChunkName: "moban" */ '../views/OrderView.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
