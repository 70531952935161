import Vue from 'vue'
import App from './App.vue'
import router from './router'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/style.css'

import http from "./server/http";

Vue.prototype.$http = http

router.beforeEach((to, from,next) => {
  if(to !== from){
    sessionStorage.setItem("router_path",to.path)
  }
  next()
})

Vue.config.productionTip = false
Vue.use(ElementUI)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
