<template>
  <div>
    <div class="logo-con">
      <img class="login-img" src="./../assets/images/mumo.jpg" alt="MUMO">
    </div>
    <el-menu
        :default-active="routePage"
        :router="true"
        class="el-menu-vertical-demo"
        background-color="#545c64"
        text-color="#fff"
        active-text-color="#ffd04b">
      <el-menu-item index="/order">
        <i class="el-icon-document"></i>
        <span slot="title">订单管理</span>
      </el-menu-item>
      <el-menu-item index="/moban">
        <i class="el-icon-tickets"></i>
        <span slot="title">表单模板</span>
      </el-menu-item>
      <el-menu-item index="/yuanqu">
        <i class="el-icon-school"></i>
        <span slot="title">园区列表</span>
      </el-menu-item>
      <el-menu-item index="/">
        <i class="el-icon-user"></i>
        <span slot="title">后台账号</span>
      </el-menu-item>

    </el-menu>
  </div>

</template>

<script>
export default {
  name: "leftNav",
  data(){
    return{
      routePage: "/order",
    }
  },
  methods: {

  },
  mounted() {
    this.routePage = sessionStorage.getItem("router_path");
  }
}
</script>

<style scoped>
.logo-con{
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #333333;
}
.login-img{
  height: 48px;
}
.el-menu-vertical-demo{
  height: 100%;
}
</style>
